import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { KAKAO_REST_API } from 'config';
import { useTranslation } from 'react-i18next';
import { RootState, useAppSelector } from 'model';
import TextFiledSearch from './text-filed-search';
import { IAddress } from '@/types/infra';
import { ILocation } from '@/types/location';

type Props = {
  style?: React.CSSProperties;
  onChange?: (obj: IAddress) => void; // ------------------- onChange
  disabled?: boolean; // ------------------- disabled
  value?: string;
  [key: string]: any;
};
export default function TextSearchAddress({ value, disabled, onChange, style }: Props) {
  const { t } = useTranslation('common');
  const {
    location: { locations },
  } = useAppSelector((state: RootState) => state);
  const open = useDaumPostcodePopup();
  const [address, setAddress] = React.useState('');

  useEffect(() => {
    if (value) {
      setAddress(value);
    }
  }, [value]);

  // 카카오API
  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = '';
    if (data.addressType === 'R') {
      if (data.bname !== '') extraAddress += data.bname;
      if (data.buildingName !== '') extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
      setAddress(data.roadAddress);
    }

    // ---------------- 위도경도 API호출
    fetch(`https://dapi.kakao.com/v2/local/search/address.json?query=${fullAddress}`, {
      headers: {
        Authorization: `KakaoAK ${KAKAO_REST_API}`,
      },
    })
      .then(res => res.json())
      .then(res => {
        const { road_address } = res.documents[0];
        const { seq } = locations.find((element: ILocation) => {
          if (road_address.region_1depth_name.includes(element.name)) {
            return element;
          }
          return element.name === road_address.region_1depth_name;
        });
        const payload = {
          ...data,
          locationSeq: seq,
          latitude: road_address?.y,
          longitude: road_address?.x,
          post_code: data?.zonecode,
          // address_jibun: data?.jibunAddress,
          address: data?.roadAddress,
        };
        if (onChange) {
          onChange(payload);
        }
      });
  };

  return (
    <Element style={style}>
      <TextFiledSearch
        // disabled={disabled}
        disabled={true}
        defaultValue={address}
        style={{ width: '100%' }}
        placeholder={t('common.buttons.searchAddress')}
        className="gap"
        onClick={() => {
          if (disabled) return;
          open({
            onComplete: handleComplete,
            popupKey: 'EV_POP',
            popupTitle: t('common.buttons.searchAddress'),
            defaultQuery: '',
            top: 0,
            left: 0,
          });
        }}
      />
    </Element>
  );
}
const Element = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
