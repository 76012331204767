/* eslint-disable @typescript-eslint/no-var-requires */ /* eslint-disable global-require */

// import requireMock = jest.requireMock;

export const common_logo = require('./common/logo.png');
export const auth_logo = require('./common/auth_logo.png');
export const logo_gnb = require('./common/logo_gnb.png');
export const sprite_gnb_2x = require('./common/sprite_gnb_2x.png');
export const sprite_sub_3x = require('./common/sprite_sub_3x.png');
/** ---------------------------------- @name button */
export const button_remote = require('./icon/button_remote.png');
/** ---------------------------------- @name icon */
export const icon_calendar_black = require('./icon/calendar_black.png');
export const icon_signal_black = require('./icon/signal_black.png');
export const icon_station_black = require('./icon/station_black.png');
export const icon_computer = require('./icon/computer.png');
export const icon_add_member_white = require('./icon/add_member_white.png');
export const icon_password_show = require('./icon/password_show.png');
export const icon_password_hidden = require('./icon/password_hidden.png');
export const icon_checkbox_on = require('./icon/checkbox_on.png');
export const icon_checkbox_off = require('./icon/checkbox_off.png');
export const icon_radio_on = require('./icon/radio_on.png');
export const icon_radio_off = require('./icon/radio_off.png');
export const icon_radio_disabled = require('./icon/radio_disabled.png');
export const icon_map_button = require('./icon/map-button.png');
export const icon_dashboard_button = require('./icon/dashboard-button.png');

export const icon_location_arrow = require('./icon/location_arrow.png');
export const icon_location_home = require('./icon/location_home.png');
export const icon_location_avatar = require('./icon/location_avatar.png');
export const icon_money_white = require('./icon/money_white.png');
export const icon_home_white = require('./icon/home_white.png');
export const icon_delete_gray = require('./icon/delete_gray.png');
export const icon_delete_black = require('./icon/delete_black.png');
export const icon_delete_red = require('./icon/delete_red.png');
export const icon_edit_gray = require('./icon/edit_gray.png');
export const icon_edit_black = require('./icon/edit_black.png');
export const icon_edit_white = require('./icon/edit_white.png');
export const icon_email_white = require('./icon/ic_email.svg');
export const icon_plus_white = require('./icon/plus_white.png');
export const icon_download = require('./icon/download.png');
export const icon_download_white = require('./icon/download_white.png');
export const icon_upload = require('./icon/upload.png');
export const icon_more = require('./icon/more.png');
export const icon_filter = require('./icon/filter.png');
export const icon_filter_on = require('./icon/filter_on.png');

export const icon_gnb_more_black = require('./icon/gnb_more_black.png');
export const icon_gnb_expand_black = require('./icon/gnb_expand_black.png');
export const icon_gnb_sub = require('./icon/gnb_sub.png');
export const icon_setting_white = require('./icon/setting_white.png');
export const icon_setting_black = require('./icon/setting_black.png');
export const icon_add_black = require('./icon/add_black.png');
export const icon_charge_2ch = require('./icon/charge_2ch.png');
export const icon_close = require('./icon/close.png');
export const icon_close_black = require('./icon/close_black.png');
export const icon_search = require('./icon/search.png');
export const icon_search_gray = require('./icon/search_gray.png');
export const icon_alert = require('./icon/alert.png');
export const icon_calendar = require('./icon/calendar.png');
export const icon_location = require('./icon/location.png');
export const icon_location_gray = require('./icon/location_gray.png');
export const icon_location_over = require('./icon/location_over.png');
export const icon_calendar_white = require('./icon/calendar_white.png');
export const icon_signal_white = require('./icon/signal_white.png');

// arrow
export const icon_arrow_black_down = require('./icon/arrow_black_down.png');
export const icon_arrow_white_down = require('./icon/arrow_white_down.png');
export const icon_arrow_gray_down = require('./icon/arrow_gray_down.png');
export const icon_arrow_down_gray = require('./icon/arrow_down_gray.png');
export const icon_arrow_down_black = require('./icon/arrow_down_black.png');
export const icon_arrow_up_gray = require('./icon/arrow_up_gray.png');
export const icon_arrow_up_black = require('./icon/arrow_up_black.png');
export const icon_arrow_prev_black = require('./icon/arrow_prev_black.png');
// extension
export const icon_ext_excel = require('./icon/ext_excel.png');
export const icon_ext_pdf = require('./icon/ext_pdf.png');

/** ---------------------------------- @name pages */
export const dashboard_widget_disorder = require('./pages/dashboard_widget_disorder.png');
export const dashboard_widget_charge1 = require('./pages/dashboard_widget_charge1.png');
export const dashboard_widget_charge2 = require('./pages/dashboard_widget_charge2.png');

export const error_401_img = require('./pages/error_401_img.png');
export const error_404_img = require('./pages/error_404_img.png');
export const error_500_img = require('./pages/error_500_img.png');
export const auth_mypage = require('./pages/auth_mypage.png');
export const auth_screen_sign_in = require('./pages/sign_in.png');
export const auth_screen_sign_up = require('./pages/register.png');
export const auth_screen_forgot_password = require('./pages/forgot_password.png');
export const warningMark = require('./icon/warning_mark.png');
export const downloadIcon = require('./icon/download_ic.svg');

export const crocusSymbol = require('./icon/crocus_symbol.png');

export const crocusFullLogo = require('./icon/crocus_full_logo.png');

export const subMenuIcon = require('./icon/icon_sub_menu.svg');

export const alertIcon = require('./icon/ic_alert.png');

export const languageIconKO = require('./icon/ic_lang_ko.png');

export const icons = {
  menu: {
    dashboard: {
      default: {
        image: require('./icon/menu/dashboard_icon_default.svg'),
        alt: '',
      },
      disabled: {
        image: require('./icon/menu/dashboard_icon_disabled.svg'),
        alt: '',
      },
      activated: {
        image: require('./icon/menu/dashboard_icon_activated.svg'),
        alt: '',
      },
    },
    infra: {
      default: {
        image: require('./icon/menu/infra_icon_default.svg'),
        alt: '',
      },
      disabled: {
        image: require('./icon/menu/infra_icon_disabled.svg'),
        alt: '',
      },
      activated: {
        image: require('./icon/menu/infra_icon_activated.svg'),
        alt: '',
      },
    },
    chargingPrice: {
      default: {
        image: require('./icon/menu/chargingPrice_icon_default.svg'),
        alt: '',
      },
      disabled: {
        image: require('./icon/menu/chargingPrice_icon_disabled.svg'),
        alt: '',
      },
      activated: {
        image: require('./icon/menu/chargingPrice_icon_activated.svg'),
        alt: '',
      },
    },
    report: {
      default: {
        image: require('./icon/menu/report_icon_default.svg'),
        alt: '',
      },
      disabled: {
        image: require('./icon/menu/report_icon_disabled.svg'),
        alt: '',
      },
      activated: {
        image: require('./icon/menu/report_icon_activated.svg'),
        alt: '',
      },
    },
    sales: {
      default: {
        image: require('./icon/menu/sales_icon_default.svg'),
        alt: '',
      },
      disabled: {
        image: require('./icon/menu/sales_icon_disabled.svg'),
        alt: '',
      },
      activated: {
        image: require('./icon/menu/sales_icon_activated.svg'),
        alt: '',
      },
    },
    profileSetting: {
      default: {
        image: require('./icon/menu/profileSetting_icon_default.svg'),
        alt: '',
      },
      disabled: {
        image: require('./icon/menu/profileSetting_icon_disabled.svg'),
        alt: '',
      },
      activated: {
        image: require('./icon/menu/profileSetting_icon_activated.svg'),
        alt: '',
      },
    },
    setting: {
      default: {
        image: require('./icon/menu/setting_icon_default.svg'),
        alt: '',
      },
      disabled: {
        image: require('./icon/menu/setting_icon_disabled.svg'),
        alt: '',
      },
      activated: {
        image: require('./icon/menu/setting_icon_activated.svg'),
        alt: '',
      },
    },
    message: {
      default: {
        image: require('./icon/menu/message_icon_default.svg'),
        alt: '',
      },
      disabled: {
        image: require('./icon/menu/message_icon_disabled.svg'),
        alt: '',
      },
      activated: {
        image: require('./icon/menu/message_icon_activated.svg'),
        alt: '',
      },
    },
    member: {
      default: {
        image: require('./icon/menu/member_icon_default.svg'),
        alt: '',
      },
      disabled: {
        image: require('./icon/menu/member_icon_disabled.svg'),
        alt: '',
      },
      activated: {
        image: require('./icon/menu/member_icon_activated.svg'),
        alt: '',
      },
    },
    app: {
      default: {
        image: require('./icon/menu/app_icon_default.svg'),
        alt: '',
      },
      disabled: {
        image: require('./icon/menu/app_icon_disabled.svg'),
        alt: '',
      },
      activated: {
        image: require('./icon/menu/app_icon_activated.svg'),
        alt: '',
      },
    },
  },
  chevronLeft: {
    image: require('./icon/chevron-left.svg'),
    alt: '',
  },
  chevronLeftBlue: {
    image: require('./icon/chevron-left-hover.svg'),
    alt: '',
  },
  chevronLeftBlack: {
    image: require('./icon/chevron-left-focus.svg'),
    alt: '',
  },
  chevronLeftWhite: {
    image: require('./icon/chevron-left-white.svg'),
    alt: '',
  },
  chevronRight: {
    image: require('./icon/chevron-right.svg'),
    alt: '',
  },
  chevronRightBlue: {
    image: require('./icon/chevron-right-hover.svg'),
    alt: '',
  },
  chevronRightBlack: {
    image: require('./icon/chevron-right-focus.svg'),
    alt: '',
  },
  chevronRightWhite: {
    image: require('./icon/chevron-right-white.svg'),
    alt: '',
  },
  validationFails: {
    image: require('./icon/validation_fail.svg'),
    alt: '',
  },
  validationSuccess: {
    image: require('./icon/validation_success.svg'),
    alt: '',
  },
  warningTriangle: {
    image: require('./icon/warning_triangle.svg'),
    alt: '',
  },
  editDefault: {
    image: require('./icon/edit_gray.png'),
    alt: '',
  },
  editHover: {
    image: require('./icon/edit_hover.png'),
    alt: '',
  },
  editFocused: {
    image: require('./icon/edit_focus.png'),
    alt: '',
  },
  removeDefault: {
    image: require('./icon/delete_gray.png'),
    alt: '',
  },
  removeHover: {
    image: require('./icon/delete_hover.png'),
    alt: '',
  },
  removeFocused: {
    image: require('./icon/delete_focus.png'),
    alt: '',
  },
  noSearchResult: {
    image: require('./icon/no_search_result.png'),
    alt: '',
  },
  noStations: {
    image: require('./icon/no_stations.png'),
    alt: '',
  },
  noVendors: {
    image: require('./icon/no_vendors.png'),
    alt: '',
  },
  noSalesData: {
    image: require('./icon/no_sales_data.svg'),
    alt: '',
  },
  chevronUpBlack: {
    image: require('./icon/chevron_up_black.png'),
    alt: '',
  },
  chevronDownBlack: {
    image: require('./icon/chevron_down_black.png'),
    alt: '',
  },
  chevronDownGray: {
    image: require('./icon/chevron_down_gray.png'),
    alt: '',
  },
  filterDefault: {
    image: require('./icon/filter_default.svg'),
    alt: '',
  },
  filterActive: {
    image: require('./icon/filter_active.svg'),
    alt: '',
  },
  download: {
    image: require('./icon/download_ic.svg'),
    alt: '',
  },
};
