import { useAppDispatch } from 'model';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ChargingStatusListPresenter from './ChargingStatusList.presenter';
import { Paging } from '@/lib/apis/infra/type';
import { actions } from '@/model/modules/chargingStatus';
import { getExcel } from '@/pages/common';
import { API_HOST } from '@/config/index';
import { getTransactions, getTransactionsExcelFile } from '@/lib/apis/sales';

export default function ChargingStatusListContainer() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const searchQueryString = searchParams.get('search') || '';
  const [searchKeyword, setSearchKeyword] = useState(searchQueryString || '');
  const fetchList = async (paging: Paging) => {
    if (!paging.search) delete paging.search;
    const { isSuccess, data, meta } = await getTransactions(paging);
    if (isSuccess)
      dispatch(
        actions.setList({
          list: data,
          totalCount: meta.paging?.total_count ?? NaN,
        }),
      );
  };
  const pageHandler = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?page=${page}`);
    }
  };

  const handleExcelDownload = async () => {
    await getTransactionsExcelFile({
      payload: {
        size: itemsPerPage,
        page: currentPage,
        search: searchKeyword,
      },
      fileName: `충전 현황_${dayjs().format('YYYY_MM_DD')}.xlsx`,
    });
  };

  const handleSearch = () => {
    fetchList({
      size: itemsPerPage,
      search: searchKeyword,
      page: currentPage,
    });
  };
  useEffect(() => {
    fetchList({
      size: itemsPerPage,
      page: currentPage,
      search: searchKeyword,
    });
  }, [page]);
  return (
    <ChargingStatusListPresenter
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      pageHandler={pageHandler}
      handleExcelDownload={handleExcelDownload}
      searchKeyword={searchKeyword}
      handleSearchKeyword={setSearchKeyword}
      handleSearch={handleSearch}
    />
  );
}
