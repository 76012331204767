import { useAppDispatch } from 'model';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext } from 'lib';
import dayjs from 'dayjs';
import TroubleReportsListPresenter from './TroubleReportsList.presenter';
import { actions } from '@/model/modules/troubleReports';
import { getTroubleReportsExcelFile, getTroubleReportsList } from '@/lib/apis/operation/trouble-reports';
import { Paging } from '@/lib/apis/infra/type';

export default function TroubleReportsListContainer() {
  const { cpo_seq } = useContext();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(Number(page));
  const searchQuery = searchParams.get('search') || '';
  const [search, setSearch] = useState(searchQuery);

  const fetchList = async (paging: Paging) => {
    const payload = {
      size: paging.size,
      page: paging.page,
      cpo_seq: paging.cpo_seq,
      search: paging.search,
    };

    if (cpo_seq) {
      const { isSuccess, data, meta } = await getTroubleReportsList(payload);
      if (isSuccess)
        dispatch(
          actions.setList({
            list: data,
            totalCount: meta.paging?.total_count ?? 0,
          }),
        );
    }
  };

  const queryParamsURL = ({ page, search }: { page?: number; search?: string }) => {
    const queryParams = [];
    if (search) {
      queryParams.push(`search=${encodeURIComponent(search)}`);
    }
    queryParams.push(`page=${page ?? currentPage}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const pageHandler = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?${queryParamsURL({ page, search })}`);
    }
  };

  const handleExcelDownload = async () => {
    const payload = {
      size: itemsPerPage,
      page: currentPage,
      cpo_seq,
      search,
    };

    if (cpo_seq) {
      await getTroubleReportsExcelFile({
        payload,
        fileName: `장애신고이력목록_${dayjs().format('YYYY_MM_DD')}.xlsx`,
      });
    }
  };

  const handleSearch = () => {
    navigate(`?${queryParamsURL({ search })}`);
    fetchList({
      size: itemsPerPage,
      page: currentPage,
      cpo_seq,
      search,
    });
  };

  useEffect(() => {
    fetchList({ size: itemsPerPage, page: currentPage, cpo_seq, search });
  }, [page]);

  return (
    <TroubleReportsListPresenter
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      pageHandler={pageHandler}
      handleExcelDownload={handleExcelDownload}
      search={search}
      setSearch={setSearch}
      handleSearch={handleSearch}
    />
  );
}
