import React from 'react';
import { App } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppSelector } from '@/model/index';
import { COLOR } from '@/styles/index';
import { addComma, Button, H3, Row, Text } from '@/lib/index';
import { DropDownCountries, PopupContainer, PopupCreate, SubmitCancel, TextFiledSearch } from '@/components/form';
import { VendorListViewProps } from './VendorList.types';
import { icon_plus_white } from '@/assets/images';
import DownloadButton from '@/components/atoms/Buttons/DownloadButton';
import VendorsTable from '@/components/organisms/Tables/VendorsTable';

/**
 * @name 제조사등록관리 리스트
 * @description
 */
export default function VendorListPresenter({
  onRegister,
  itemsPerPage,
  currentPage,
  pageHandler,
  sortingHandler,
  deletePopupVisible,
  handleDelete,
  onDeleteConfirm,
  searchKeyword,
  handleSearchKeyword,
  handleSearch,
  selectedLocation,
  handleSelectLocation,
  handleExcelDownload,
  queryString,
}: VendorListViewProps) {
  const { t } = useTranslation('common');
  const { totalCount } = useAppSelector(state => state.vendor);

  return (
    <App>
      <Header>
        <Row>
          <div style={{ marginRight: 20 }}>
            <H3 color={COLOR.BLACK3}>{t('pages.infra.vendorManage.title')}</H3>
            <Text color={COLOR.BLACK3} className="body6">
              {`${t('common.unit.total')} ${addComma(totalCount)}${t('common.unit.cases')}`}
            </Text>
          </div>
          {/* TODO Backend API에서 지역만 선택하는 것 */}
          {/* <DropDownCountries style={{ width: 200, marginLeft: 20 }} onChange={(data: any) => {}} /> */}
          {/* <DropDownLocations value={selectedLocation} onChange={handleSelectLocation} /> */}
          <div className="search" style={{ marginLeft: 20 }}>
            <TextFiledSearch
              placeholder={t('pages.infra.vendorManage.searchPlaceholder')}
              defaultValue={searchKeyword}
              onChange={str => {
                handleSearchKeyword(str);
              }}
              onSubmit={() => {
                handleSearch();
              }}
            />
          </div>
        </Row>
        <ButtonWrapper>
          <Button
            icon={icon_plus_white}
            label={t('pages.infra.vendorManage.register')}
            style={{ marginRight: 16 }}
            onClick={onRegister}
          />
          <DownloadButton onClick={handleExcelDownload} />
        </ButtonWrapper>
      </Header>
      <VendorsTable
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        pageHandler={pageHandler}
        sortingHandler={sortingHandler}
        handleDelete={handleDelete}
        queryString={queryString}
        onRegister={onRegister}
      />
      {/* <Popup /> */}
      <PopupContainer onClose={() => handleDelete.popup(false)} visible={deletePopupVisible}>
        <PopupCreate
          warning
          title={t('common.toast.removeVendor')}
          message={t('common.toast.askRemoveVendor')}
          footer={
            <SubmitCancel
              confirmLabel={t('common.buttons.removeAction')}
              onCancel={() => handleDelete.popup(false)}
              onConfirm={onDeleteConfirm}
            />
          }
        />
      </PopupContainer>
    </App>
  );
}

const Header = styled.div`
  // styles
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
