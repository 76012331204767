import { authAction } from '@/model/modules';
import RegisterCpoPresenter from './RegisterCpo.presenter';
import { useAppDispatch, useAppSelector } from 'model';
import { useEffect, useState } from 'react';
import { SESSION_STORAGE, useRoutes } from '@/lib/index';
import { MENU } from '@/pages/router';
import { ReqPutCompany } from '@/lib/apis/auth/type';
import { putCompany } from '@/lib/apis/auth';

export default function RegisterCpoContainer() {
  const { user } = useAppSelector(state => state.auth);
  const { navigate } = useRoutes();
  const [popupVisible, setPopupVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [info, setInfo] = useState<ReqPutCompany>({
    company: {
      name: '',
      code: '',
      phone: '',
      cs_phone: '',
      address: '',
      corporation_number: '',
    },
    admin: {
      email: user?.email,
      name: '',
      phone: '',
    },
  });
  const [requiredFieldsWarning, setRequiredFieldsWarning] = useState({
    name: false,
    address: false,
    businessCode: false,
    companyName: false,
    businessNumber: false,
  });
  const dispatch = useAppDispatch();
  const setPopup = (msg: string) => {
    setMessage(msg);
    setPopupVisible(true);
  };

  const onSubmit = async () => {
    let checkResult = true;
    setRequiredFieldsWarning({
      address: false,
      businessCode: false,
      businessNumber: false,
      companyName: false,
      name: false,
    });
    console.log(info);
    if (user.role.code !== 'ADMIN') {
      if (!info.company.code) {
        // setPopup('비지니스 코드는 필수 입력 값입니다.');
        setRequiredFieldsWarning(prev => ({
          ...prev,
          businessCode: true,
        }));
        checkResult = false;
      }
      if (!info.company.address) {
        // setPopup('주소는 필수 입력 값입니다.');
        setRequiredFieldsWarning(prev => ({
          ...prev,
          address: true,
        }));
        checkResult = false;
      }
      if (!info.company.corporation_number) {
        // setPopup('사업자 번호는 필수 입력 값입니다.');
        setRequiredFieldsWarning(prev => ({
          ...prev,
          businessNumber: true,
        }));
        checkResult = false;
      }
      console.clear();
      console.log(info.company.name);
      console.log(!info.company.name);
      //=======######========
      if (!info.company.name) {
        // console.log(info.company.name.toString() === '');
        // setPopup('회사명 필수 입력 값입니다.');
        setRequiredFieldsWarning(prev => ({
          ...prev,
          companyName: true,
        }));
        checkResult = false;
      }
    }
    if (!info.admin.name) {
      // setPopup('회사명 필수 입력 값입니다.');
      setRequiredFieldsWarning(prev => ({
        ...prev,
        name: true,
      }));
      checkResult = false;
    }
    ///=======TEST
    try {
      if (!checkResult) return;
      const res = await putCompany(info);
      if (res.isSuccess) {
        navigate(MENU.AUTH.SIGN_IN);
        return;
      } else {
        setPopup('관리자에 문의하세요');
      }
    } catch (e) {
      // company.code가 중복인경우 500이 떨어져서 예외처리했어요.
      setPopup('비지니스 코드가 이미 존재합니다 다른 값으로 입력해주세요.');
      return;
    }
  };
  useEffect(() => {
    if (!user?.email) {
      navigate(MENU.AUTH.SIGN_IN);
    }
  }, [user]);
  return (
    <RegisterCpoPresenter
      email={user?.email}
      requiredFieldsWarning={requiredFieldsWarning}
      info={info}
      setInfo={setInfo}
      onSubmit={onSubmit}
      popupVisible={popupVisible}
      setPopupVisible={setPopupVisible}
      message={message}
    />
  );
}
