/**
 * @name Container
 */
import { useEffect, useState } from 'react';
import { Events, useAxios } from 'lib';
import { GUIDE_EVENT } from 'model/modules';
import { API_HOST } from 'config';
import Presenter from './presenter';

export type adminsProps = {
  admin: {
    seq: number;
    email: string;
    name: string;
    phone: string;
    language_code: null;
    country_code2: string;
    timezone: string;
    status: string;
    created_at: string;
    created_by: number;
    last_modified_at: string;
    last_modified_by: number;
    withdrew_at: string;
  };
  cpo: {
    seq: number;
    code: string;
    old_code: null;
    name: string;
    logo_path: null;
    corporation_number: string;
    address: string;
    phone: string;
    cs_phone: string;
    station_count: number;
    last_station_number: number;
    default_tariff_types: string;
    created_at: string;
    created_by: number;
    last_modified_at: string;
    last_modified_by: number;
    bid: null;
    bkey: null;
  };
  onSubmit: (payload: any) => void; // ## onSubmit 함수 추가
} | null;

export default function Container() {
  // hooks

  const [admins, setAdmins] = useState<adminsProps>(null);
  const { patch, get } = useAxios();
  // const {loading, get} = useAxios()
  // const dispatch = useAppDispatch()
  // const {data} = useAppSelector(state => state.guide)
  // 로그아웃,초기화
  async function adminsMe() {
    const res = await get(`${API_HOST}/admins/me`);
    if (res?.isSuccess) {
      setAdmins(res.data);
    }
  }
  //*--------------------------------------------------*
  async function onSubmit(payload: any) {
    console.log(payload);
    const res = await patch(`${API_HOST}/admins/me/${admins?.cpo.seq}`, payload);
    console.log(res);
    // const res = await post(url, data);
    // const res = await axios(config)
  }
  //*--------------------------------------------------*
  useEffect(() => {
    adminsMe();

    // addListener
    Events.addListener(GUIDE_EVENT.SUBMIT, onSubmit);
    return () => {
      // removeListener
      Events.removeListener(GUIDE_EVENT.SUBMIT, onSubmit);
    };
  }, []);

  return !!admins && <Presenter admin={admins.admin} cpo={admins.cpo} onSubmit={onSubmit} />;
}
