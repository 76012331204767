import styled from 'styled-components';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';

export const styleListKeyValue = {
  padding: '6px 0',
  'overflow-wrap': 'anywhere',
  'white-space': 'normal',
};
export const Header = styled.div`
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 16px 24px 16px;
`;

export const SummaryWrapper = styled.div`
  min-width: 780px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TableTitle = styled.p`
  ${typography.headline5};
  color: ${COLOR.GRAY1};
`;

export const ListWrapper = styled.div``;

export const CustomGap = styled.div`
  margin: 20px 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RowHeader = styled.div`
  flex: 1;
  padding: 14px 8px;
  background-color: ${COLOR.BLUE8};
  border-bottom: 1px solid ${COLOR.BLUE_GRAY9};
  text-align: center;
  ${typography.headline6};
  color: ${COLOR.GRAY1};
`;

export const Cell = styled.div`
  padding: 16px 8px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  flex: 1;
  text-align: center;
  ${typography.body4};
  color: ${COLOR.BLACK3};
`;
