import React, { useEffect, useState } from 'react';
import { App, BackButton } from 'components';
import { useTranslation } from 'react-i18next';
import { addComma, calculateChargeDurationInMinutes, formatDateTime, Tab } from 'lib';
import { ListKeyValue } from '@/components/form';
import {
  CustomGap,
  Header,
  ListWrapper,
  styleListKeyValue,
  SummaryWrapper,
  TableTitle,
  Wrapper,
} from './ChargepointDetail.styles';
import { displayNull } from '@/lib/util/displayData';
import { TariffUserType } from '@/lib/apis/tariff/type';
import { ChargingStatusPurchase } from '@/types/chargingStatus';
import { useAppSelector } from '@/model/index';
import Badge from '@/components/atoms/Badge';
import { DeviceStatus, IDeviceStatusItem } from '@/lib/apis/dashborad/type';
import { BadgeMode, BadgeType } from '@/components/atoms/Badge/Badge.type';
import { IConnector } from '@/types/infra';

export default function ChargepointDetailOperationPresenter({ handleBackToList }: { handleBackToList: () => void }) {
  const { view } = useAppSelector(state => state.chargepoint);
  const { t } = useTranslation('common');
  const tabMenu = [
    { label: t('pages.operation.chargepoint.detail.tabs.chargerSummaryInfo') },
    { label: t('pages.operation.chargepoint.detail.tabs.connectorInfo') },
  ];
  const [selected, setSelected] = useState(0);
  const [purchaseFilteredValues, setPurchaseFilteredValues] = useState({
    cancelAmountTotal: 0,
    chargingDuration: '-',
    chargingAmountTotal: 0,
    finalApprovedAmount: 0,
    chargingFinishedTime: '-',
    idTag: '-',
    userType: 3,
    pricePerkW: '-',
    preApprovalNumber: '-',
    chargingStartTime: '-',
    chargingEndTime: '-',
  });

  const renderMemberType = (type: number | undefined) => {
    if (type === undefined) {
      return '-';
    }
    if (type === TariffUserType.NONE) {
      return '비회원';
    }
    if (type === TariffUserType.USER) {
      return '회원';
    }
    return '로밍회원';
  };

  const getChargingDuration = () => {
    const filteredValue = {
      cancelAmountTotal: 0,
      chargingDuration: '-',
      chargingAmountTotal: 0,
      chargingFinishedTime: '-',
      idTag: '-',
      userType: 3,
      pricePerkW: '-',
      preApprovalNumber: '-',
      chargingStartTime: '-',
      chargingEndTime: '-',
    };
    for (let i = 0; i < view.purchases.length; i += 1) {
      const purchase = view.purchases[i];
      if (purchase.status === 'COMPLETED') {
        filteredValue.chargingStartTime = purchase.created_at;
        filteredValue.chargingEndTime = purchase.last_modified_at;
        filteredValue.chargingDuration = calculateChargeDurationInMinutes(
          purchase.created_at,
          purchase.last_modified_at,
        ); // 충전시간
        filteredValue.chargingAmountTotal = parseFloat(purchase.amount); // 충전요금
        filteredValue.chargingFinishedTime = purchase.last_modified_at;
        filteredValue.idTag = purchase.id_tag;
        filteredValue.userType = purchase.user_type;
        filteredValue.pricePerkW = purchase.price_per_kw;
        filteredValue.preApprovalNumber = purchase.approval_number;
      }
      if (purchase.status === 'CANCEL') {
        filteredValue.cancelAmountTotal += parseFloat(purchase.amount);
      }
    }
    setPurchaseFilteredValues({
      ...filteredValue,
      finalApprovedAmount: filteredValue.chargingAmountTotal - filteredValue.cancelAmountTotal,
    });
  };

  const getChargingAmountkWh = () => {
    return ((view.meter_last - view.meter_start) * 0.001).toFixed(2);
  };

  const getDeviceStatus = (status: DeviceStatus) => {
    const obj = {
      Available: {
        mode: BadgeMode.Available,
        label: '사용가능',
      },
      Preparing: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      Charging: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      SuspendedEVSE: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      SuspendedEV: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      Finishing: {
        mode: BadgeMode.Available,
        label: '사용가능',
      },
      Reserved: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      Unavailable: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      Faulted: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      CommunicationFault: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
    };
    const result = obj[status] ?? {
      mode: BadgeMode.Unavailable,
      label: '사용불가능',
    };
    return result;
  };

  useEffect(() => {
    // getChargingDuration();
  }, [view]);

  return (
    <App>
      <Header>
        <BackButton label={view ? view.name : ''} onClick={handleBackToList} />
      </Header>
      <main className="inc_form" style={{ width: 780 }}>
        <Tab
          selected={selected}
          style={{ marginBottom: 32 }}
          info={tabMenu}
          onChange={({ index }) => {
            setSelected(index);
          }}
        />
      </main>
      <Wrapper>
        {view && selected === 0 && (
          <SummaryWrapper>
            <TableTitle>{t('pages.operation.chargepoint.detail.chargerSummaryInfo.chargerInfo')}</TableTitle>
            <ListWrapper>
              <ListKeyValue
                title={t('pages.operation.chargepoint.detail.chargerSummaryInfo.chargerInfoTitles.stationName')}
                value={view.station.name}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('pages.operation.chargepoint.detail.chargerSummaryInfo.chargerInfoTitles.chargerName')}
                value={view.name}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('pages.operation.chargepoint.detail.chargerSummaryInfo.chargerInfoTitles.communicationId')}
                value={displayNull(view.channel_id)}
                valueStyle={styleListKeyValue}
              />
            </ListWrapper>
            <CustomGap />
            <TableTitle>{t('pages.operation.chargepoint.detail.chargerSummaryInfo.chargerStatusInfo')}</TableTitle>
            <ListWrapper>
              <ListKeyValue
                title={t('pages.operation.chargepoint.detail.chargerSummaryInfo.chargerStatusTitles.chargerId')}
                value={view.code}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('pages.operation.chargepoint.detail.chargerSummaryInfo.chargerStatusTitles.chargerStatus')}
                component={
                  <Badge
                    mode={
                      getDeviceStatus(
                        view.device_status_items?.find((device: IDeviceStatusItem) => device.connector_number === 0)
                          ?.status as DeviceStatus,
                      ).mode as unknown as BadgeType
                    }
                    label={
                      getDeviceStatus(
                        view.device_status_items?.find((device: IDeviceStatusItem) => device.connector_number === 0)
                          ?.status as DeviceStatus,
                      ).label
                    }
                  />
                }
                valueStyle={styleListKeyValue}
              />
            </ListWrapper>
            <CustomGap />
            <TableTitle>{t('pages.operation.chargepoint.detail.chargerSummaryInfo.infraInfo')}</TableTitle>
            <ListWrapper>
              <ListKeyValue
                title={t('pages.operation.chargepoint.detail.chargerSummaryInfo.infraInfoTitles.vendor')}
                value={view.model.vendor.name}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('pages.operation.chargepoint.detail.chargerSummaryInfo.infraInfoTitles.model')}
                value={view.model.name}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('pages.operation.chargepoint.detail.chargerSummaryInfo.infraInfoTitles.faultCodeFileVersion')}
                value={displayNull(view.model.trouble_version)}
                valueStyle={styleListKeyValue}
              />
            </ListWrapper>
          </SummaryWrapper>
        )}
        {view && selected === 1 && (
          <SummaryWrapper>
            <TableTitle>{t('pages.operation.chargepoint.detail.connectorInfo.info')}</TableTitle>
            {view.connectors.map((connector: IConnector) => (
              <ListWrapper key={connector.seq}>
                <ListKeyValue
                  title={t('pages.operation.chargepoint.detail.connectorInfo.id')}
                  value={connector.code}
                  valueStyle={styleListKeyValue}
                />
                <ListKeyValue
                  title={t('pages.operation.chargepoint.detail.connectorInfo.type')}
                  value={t(`common.connector.${connector.types}`)}
                  valueStyle={styleListKeyValue}
                />
                <ListKeyValue
                  title={t('pages.operation.chargepoint.detail.connectorInfo.status')}
                  component={
                    <Badge
                      mode={
                        getDeviceStatus(
                          view.device_status_items.find(
                            (device: IDeviceStatusItem) => connector.connector_number === device.connector_number,
                          )?.status as DeviceStatus,
                        ).mode as unknown as BadgeType
                      }
                      label={
                        getDeviceStatus(
                          view.device_status_items.find(
                            (device: IDeviceStatusItem) => connector.connector_number === device.connector_number,
                          )?.status as DeviceStatus,
                        ).label
                      }
                    />
                  }
                  valueStyle={styleListKeyValue}
                />
                <CustomGap />
              </ListWrapper>
            ))}
          </SummaryWrapper>
        )}
      </Wrapper>
    </App>
  );
}
